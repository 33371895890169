import router from './router'
import store from './store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style

// 白名单
const WHITE_LIST = ['/login','/unsigned'] // 白名单

/**
 * 路由前置守卫
 * 获取Token,
 * **/
router.beforeEach(async (to, from, next)=>{
  NProgress.start()
  const hasToken = store.state.user.token
  var sUserAgent = navigator.userAgent.toLowerCase()
  if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(sUserAgent)) {
    console.log(sUserAgent)
    window.location.href = 'https://worksy.top/h5/#/login'
    NProgress.done()
    // if (WHITE_MoblieExamine.includes(to.path)) {
    //   next({ path: '/login', query: { redirect: to.path, ...to.query }})
    // } else {
    //   if (!window.navigator.userAgent.includes('DingTalk')) {
    // }
    // }
  }else {
    if(hasToken){
      if(to.path === '/login'){
        next({path: '/'})
        NProgress.done()
      }else{
        next()
        NProgress.done()
      }
    }else{
      if(WHITE_LIST.includes(to.path)){
        next()
        NProgress.done()
      }else{
        next('/login')
        NProgress.done()
      }
    }
  }
  NProgress.done()
})
router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
