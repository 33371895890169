import axios from 'axios'
import store from '@/store'
// 根据环境不同引入不同api地址
import { baseApi } from '@/config'
import { Message ,MessageBox  } from 'element-ui'
import { getToken } from '@/utils/auth'
// create an axios instance
const service = axios.create({
  baseURL: baseApi, // url = base api url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})

// request拦截器 request interceptor
service.interceptors.request.use(
  config => {
    if (store.getters.token) {
      config.headers['X-Token'] = getToken()
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)
// respone拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    if(res.code){
      if (res.code !== 0) {
        if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
          MessageBox.confirm ('检测到登录授权已失效', '确认信息', {
            distinguishCancelAndClose: true,
            confirmButtonText: '重新登陆',
            cancelButtonText: '取消'
          })
            .then(() => {
              // on confirm
              store.commit('user/REMOVE_USERINFO')
              window.location.href = location.href + '/login'
            })
            .catch(() => {
              Message.error('重新登录')
            })
        }
        return Promise.reject(res.message || 'error')
      } else {
        return res
      }
    }else {
      return  res
    }

  },
  error => {
    console.log('err' + error) // for debug
    return Promise.reject(error)
  }
)

export default service
