import Cookies from 'js-cookie'

const TokenKey = 'China-Token'
const Infouser = 'China-user'
export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getUserInfo() {
  return Cookies.get(Infouser)
}

export function setUserInfo(userInfo) {
  return Cookies.set(Infouser, userInfo)
}

export function removeUserInfo() {
  return Cookies.remove(Infouser)
}
