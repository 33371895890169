import request from '@/utils/request'

// 获取验证码请求
export function login(data) {
  return request({
    url: '/captcha',
    method: 'post',
    data
  })
}
// 登录
export function verificationCode(data) {
  return request({
    url: '/login',
    method: 'post',
    data
  })
}

// 注册
export function register(data) {
  return request({
    url: `/register`,
    method: 'post',
    data
  })
}

// 用户详情
export function getUserInfotoken() {
  return request({
    url: '/api/user/detail',
    method: 'post'
  })
}

// 获取缴费通知单
export function getStreamFile(data) {
  return request({
    url: '/api/file/stream',
    method: 'post',
    responseType: 'blob',
    data
  })
}
// 获取下载链接
export function getFile(data) {
  return request({
    url: '/api/file',
    method: 'post',
    data
  })
}
