import{verificationCode} from'@/api/login'
import { getToken, setToken, removeToken, getUserInfo, setUserInfo, removeUserInfo } from '@/utils/auth'
import router, { resetRouter } from '@/router'
import { removeInvoiceLength, removeIsinvoice, removeIspaymentMeno, removePaymentMenoLength } from '@/utils/hint'

const zhzz = val => {
  if (val) {
    return JSON.parse(val)
  } else {
    return {}
  }
}
const state = {
  token: getToken() || '',   // 获取Token
  userInfo: getUserInfo() || {},  // 获取客户信息
}

const mutations = {
  /**
   * 获取Token 且将token存入本地
   * **/
  SET_TOKEN: (state, token) => {
    state.token =token
    setToken(token)
  },
  /**
   * 获取用户信息 且将用户信息存入本地
   * */
  SET_USERINFO:(state,userInfo)=>{
    state.userInfo = userInfo
    setUserInfo(JSON.stringify(userInfo))
  },
  /***
   * 退出登录 ,并且删除Token 和个人信息
   * */
  REMOVE_USERINFO: (state) => {
    state.token = ''
    state.userInfo = {}
    removeToken()
    removeUserInfo()
    removeIsinvoice()
    removeInvoiceLength()
    removeIspaymentMeno()
    removePaymentMenoLength()
  }
}

const actions = {
  /**
   * 用户登录
   * */
  async handlelogin(ctx,loginUser){
    const res =await verificationCode(loginUser)
    if(res.code === 0){
      ctx.commit('SET_TOKEN', res.data)
    }
    return  res
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
