import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/layout'),
    children:[
      {
        path:'/',
        component: () => import('@/views/userInfo')
      },
      {
        path: '/certificate',
        name: 'certificate',
        component: () => import('@/views/certifiCate/index')
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/Index.vue')
  },
  {
    path: '/unsigned',
    name: 'unsigned',
    component: () => import('@/views/reg/RegisterForm')
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: () => import('@/views/404.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
