import Cookies from 'js-cookie'

const isinvoice = 'CookiesInvoice'   // 发票显隐藏
const invoiceLength = 'CookiesInvoiceLength' // 发票数量
const ispaymentMeno = 'CookiesPaymentMeno' // 缴费通知单显示隐藏
const paymentMenoLength = 'CookiesPaymentMenoLength' // 缴费通知单数量

// 获取本地存入的值
//获取发票显隐藏的值
export function getisinvoice(){
  return Cookies.get(isinvoice)
}
// 获取发票数量的值
export function getinvoiceLength() {
  return Cookies.get(invoiceLength)
}
// 获取缴费通知单显隐藏的值
export  function  getispaymentMeno(){
  return Cookies.get(ispaymentMeno)
}
// 获取缴费通知单数量
export  function  getpaymentMenoLength(){
  return Cookies.get(paymentMenoLength)
}
// 修改发票显隐藏的值
export  function setisinvoice(value){
  return Cookies.set(isinvoice, value)
}
// 修改发票数量的值
export  function setinvoiceLength(value){
  return Cookies.set(invoiceLength, value)
}
// 修改缴费通知单显隐藏的值
export  function setispaymentMeno(value){
  return Cookies.set(ispaymentMeno, value)
}
//  修改缴费通知单数量
export  function setpaymentMenoLength(value){
  return Cookies.set(paymentMenoLength, value)
}

export function removeIsinvoice() {
  return Cookies.remove(isinvoice)
}
export function removeInvoiceLength() {
  return Cookies.remove(invoiceLength)
}
export function removeIspaymentMeno() {
  return Cookies.remove(ispaymentMeno)
}
export function removePaymentMenoLength() {
  return Cookies.remove(paymentMenoLength)
}
